@import "../../../assets/sass/variables.sass";

.cont-mv {
  display: flex;
  height: 100%;
  align-items: center;
  .file {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 18%;
    align-items: center;
    padding: 5rem 0 0;
    @include tabletLandscape {
      width: 80%; } }
  .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    @include tabletLandscape {
      align-content: center; }
    .block {
      width: 46%;
      @include tabletLandscape {
        width: 100%;
        &:first-child {
          margin-bottom: 3.5rem;
          @include smartphone {
            margin-bottom: 3rem; } } }
      p {
        text-align: left; } } } }
