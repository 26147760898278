@import "../../../assets/sass/variables.sass";
.change-mode {
    overflow: hidden;
    border-radius: 50%;
    font-size: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: var(--theme-background-btn);
    padding: 0;
    transition: all .5s ease-in-out;
    cursor: pointer;
    @include smartphoneLandscape {
        width: 2.1rem;
        height: 2.1rem;
        font-size: 1.25rem; }
    svg {
        color: var(--theme-icon-btn);
        transition: .5s ease all; }
    &:hover {
        transform: scale(1.15);
        background: var(--theme-background-hover-btn);
        svg {
            color: var(--theme-hover-icon-btn); } } }
