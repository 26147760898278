@import "./mixins";

//colors
$dark: #000000;
$black: #1c1c1c;
$white: #ffffff;
$gray-btn: #a5a5a5;
$light-gray: lighten($gray-btn, 30%);
$light: #ededed;
$text-footer: #a0a0a0;
$text-color: #6b6b6b;
$light-gray: #efefef;
$gray-link: lighten(#444444, 50%);
$sublink: #444444;
$sublink-light: lighten(#444444, 60%);
$title-light: #bcbbbb;
$color-field: #eaeaea;
$color-field-hover: darken(#eaeaea, 20%);
$color-field-focus: darken(#eaeaea, 40%);
$color-btn: darken(#efefef, 15%);
$dak-gradient: radial-gradient(circle, rgba(28, 28, 28, .7), rgba(28, 28, 28, .75), rgba(28, 28, 28 , .88), rgba(28, 28, 28 , .98), rgba(28, 28, 28 , .95));
$light-gradient: radial-gradient(circle, rgba(250, 248, 255, .7), rgba(250, 248, 255, .75), rgba(250, 248, 255, .88), rgba(250, 248, 255, .98), rgba(250, 248, 255, .95));
$light-dark: rgba(#000000, .75);
$color-arrow-light: rgba(#efefef, .18);
$color-arrow-dark: rgba(#000000, .4);
$color-arrow-light-hover: rgba(#efefef, .35);
$color-arrow-dark-hover: rgba(#000000, .5);
$gray-social: #6a6a69;
$text-social: #bbbaba;

$Blatant: 'Blatant', sans-serif;
$Anodina: 'Anodina', sans-serif;

$bold: 700;
$regular: 400;
