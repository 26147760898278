@import "../../../assets/sass/variables.sass";

.cont-pilares {
  height: 100%;
  display: flex;
  align-items: center;
  &>div {
    width: 100%; }
  .flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .pilar {
      display: flex;
      flex-direction: column;
      align-content: space-around;
      width: calc(100% / 4);
      padding: .6rem;
      @include tablet {
        width: calc(100% / 2);
        &:nth-child(1),&:nth-child(2) {
          margin-bottom: 2.5rem; } }
      @include smartphone {
        width: 100%;
        &:nth-child(1),&:nth-child(2),&:nth-child(3) {
          margin-bottom: 1rem; } }
      .icon-pilar {
        min-height: 7rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1.5rem;
        @include smartphone {
          min-height: 1rem;
          margin-bottom: 0.8rem; }
        svg {
          width: 6rem;
          transition: all .4s ease-in-out;
          height: fit-content;
          cursor: pointer;
          .cls-1 {
            fill: var(--theme-page-logo); }
          @include smartphone {
            width: 4rem;
            height: 4rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            padding: 10px; } }
        &:hover {
          svg {
            transform: scale(1.1); } } }
      &:nth-of-type(2) {
        svg {
          width: 6.5rem;
          @include smartphone {
            width: 4rem;
            height: 4rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            padding: 10px; } } }
      &:nth-of-type(3) {
        svg {
          width: 5rem;
          @include smartphone {
            width: 4rem;
            height: 4rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            padding: 10px; } } }
      &:nth-of-type(4) {
        svg {
          width: 5.5rem;
          @include smartphone {
            width: 4rem;
            height: 4rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            padding: 10px; } } }
      .name-pilar {
        text-align: center;
        font-family: $Anodina;
        font-weight: $regular;
        font-size: 1.15rem;
        color: var(--theme-short-title); } } } }
