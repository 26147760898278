@import "../../../assets/sass/variables.sass";
header {
  padding: 2rem 3.5rem;
  height: 7.4rem;
  display: flex;
  align-items: center;
  width: 100%;
  @include tabletLandscape {
    padding: 2rem 2.5rem; }
  @include smartphoneLandscape {
    padding: 1.5rem 1.5rem;
    height: auto; }
  .cont-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .logo {
      margin-bottom: 5px;
      a {
        display: flex;
        .btn-smart {
          color: var(--theme-page-logo);
          font-size: 1rem;
          display: flex;
          align-items: center;
          padding-right: 0.5rem; }
        .img-logo {
          pointer-events: none;
          width: 8.5rem;
          @include smartphone {
            width: 7.5rem; }
          .cls-1 {
            fill: var(--theme-page-logo); } }
        &:hover {
          .btn-smart {
            animation-name: hvr-wobble-horizontal;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
            cursor: pointer; } } } }
    .btn-back {
      color: var(--theme-page-logo);
      font-size: 1.65rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      padding-bottom: 5px;
      &:hover {
        animation-name: hvr-wobble-horizontal;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        cursor: pointer; } }
    .header-right {
      display: flex;
      position: relative;
      align-items: center;
      top: 12px;
      h4 {
        margin-top: -.5rem;
        margin-right: .6rem;
        margin-left: 1rem;
        font-family: $Anodina;
        font-weight: $regular;
        color: var(--theme-page-logo);
        font-size: 1rem;
        line-height: 100%;
        text-transform: uppercase;
        @include smartphoneLandscape {
          display: none; } }
      .inmo {
        transform: scale(0.8);
        animation: beat .9s infinite alternate;
        opacity: 0.5; } } } }

@keyframes beat {
  to {
    transform: scale(0.7); } }
