@import "../../../assets/sass/variables.sass";

footer {
    padding: 0 3.5rem 2rem;
    height: 12rem;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    @include tabletLandscape {
        padding: 0rem 2.5rem; }
    @include smartphoneLandscape {
        padding: 1.5rem 1.5rem 2rem;
        height: auto; }
    .cont-footer {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        @include smartphoneLandscape {
            align-items: center; }
        .left {
            padding-right: 1.5rem;
            h4 {
                font-family: $Anodina;
                font-weight: $regular;
                font-size: 1rem;
                color: var(--theme-text-footer); }
            &__text {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                h4 {
                    margin-right: .85rem;
                    padding: .2rem 0 .5rem; } } }
        .logo-iso {
            display: none;
            cursor: pointer;
            opacity: 1;
            transition: .4s;
            .logo-button {
                padding: 0.2rem; }
            svg {
                width: 8.5rem;
                @include smartphoneLandscape {
                    width: 7rem; } }
            &:hover {
                opacity: .8; } } }

    .text-reponsive {
        width: 100%;
        text-align: center;
        margin-top: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        h4 {
            font-size: .75rem; }
        &>h4 {
            margin-right: .3rem; } }

    a {
      background: var(--theme-background-btn-social);
      width: 3rem;
      height: 3rem;
      @include tablet {
        width: 2rem;
        height: 2rem; }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 5px;
      &::before {
        background: $sublink;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transition: 0.25s cubic-bezier(0.165, 0.64, 0.84, 1);
        width: 0;
        z-index: 0; }
      &::after {
        background: $sublink;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transition: 0.25s cubic-bezier(0.165, 0.64, 0.84, 1);
        width: 0;
        z-index: 0; }
      svg {
        color: $text-social;
        font-size: 1.4rem;
        z-index: 1;
        transition: 0.25s all; } } }

