@import "../../../assets/sass/variables.sass";

.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .cont-preloader {
    width: 100%;
    height: 100vh;
    background: $white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6rem;
    color: $black; } }
