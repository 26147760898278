@import "../../../assets/sass/variables.sass";

.cont-gallery {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  @include tablet {
    width: 95%; }
  @include smartphoneLandscape {
    width: 85vw;
    height: 35vh !important; }
  .item {
    position: relative;
    cursor: pointer;
    .cont-img-slide {
      position: relative;
      height: 100%;
      overflow: hidden;
      margin-bottom: 1.5rem;
      border-radius: 10px;
      @include tablet {
        height: 400px; }
      @include smartphoneLandscape {
        height: 200px; }
      img {
        object-fit: cover;
        object-position: top;
        filter: grayscale(1);
        transition: 4s ease all;
        @include smartphoneLandscape {
          width: 100%; } } }
    .cont-img-slide2 {
      position: relative;
      height: 100%;
      overflow: hidden;
      margin-bottom: 1.5rem;
      border-radius: 10px;
      @include tablet {
        height: 400px; }
      @include smartphoneLandscape {
        height: 200px; }
      img {
        height: 100%;
        object-fit: cover;
        object-position: top;
        @include smartphoneLandscape {
          width: 100%; } } }
    caption {
      position: absolute;
      top: 45%;
      left: 0; } }
  .text-on-image {
    position: absolute;
    right: 50%;
    left: 5%;
    bottom: 10%;
    .title {
      font-size: 2rem;
      line-height: 30px;
      @include smartphoneLandscape {
        line-height: 20px;
        font-size: 1.2rem; } }
    .btn-save {
        background: var(--theme-background-btn);
        border-radius: 0;
        font-family: $Anodina;
        font-weight: $bold;
        font-size: 0.8rem;
        line-height: 100%;
        padding: .8rem 1.5rem 1rem;
        color: var(--theme-btn);
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: 0.4s all;
        border-radius: 2px; } } }
.loader-image {
  width: 100%;
  height: 100%;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  transition: .4s ease all; }
.venta {
  z-index: 1;
  top: 145px;
  position: absolute;
  right: 20px;
  font-size: 1.8rem;
  font-family: "Blatant", sans-serif;
  transform: rotate(34deg);
  color: black; }
