@import "../../../assets/sass/variables.sass";

.cont-about {
  height: 100%;
  display: flex;
  align-items: center;
  &>div {
    width: 100%;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column; }
    .cont-text {
      margin-bottom: 2rem;
      width: 100%;
      @include tablet {
        margin-top: 2rem;
        margin-bottom: 0; } }
    .cont-img {
      width: 100%;
      height: 15rem;
      overflow: hidden;
      @include tabletLandscape {
        height: 13rem; }
      @include smartphone {
        height: 10rem; }
      .img-about {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    .cont-logo {
      margin-bottom: 1.8rem;
      svg {
        width: 15rem;
        @include tabletLandscape;
        width: 12rem;
        @include smartphone {
          width: 10rem; }
        .cls-1 {
          fill: var(--theme-page-logo); } } } } }
