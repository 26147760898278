$font-path: "../Fonts";

@font-face {
  src: url("#{$font-path}/Anodina-ExtraBold.otf") format("opentype");
  font-family: "Anodina";
  font-style: normal;
  font-display: swap;
  font-weight: 800; }

@font-face {
  src: url("#{$font-path}/Anodina-Regular.otf") format("opentype");
  font-family: "Anodina";
  font-style: normal;
  font-display: swap;
  font-weight: 400; }

@font-face {
  src: url("#{$font-path}/Blatant-Bold.otf") format("opentype");
  font-family: "Blatant";
  font-style: normal;
  font-display: swap;
  font-weight: 700; }

@font-face {
  src: url("#{$font-path}/Blatant.otf") format("opentype");
  font-family: "Blatant";
  font-style: normal;
  font-display: swap;
  font-weight: 400; }

@font-face {
  src: url("#{$font-path}/Montserrat-Medium.otf") format("opentype");
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 500; }
