@import "../../../assets/sass/variables.sass";

.cont-location {
  display: flex;
  align-items: center;
  height: 100%;
  .flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    @include tablet {
      flex-direction: column-reverse; }
    .description {
      width: 40%;
      @include tablet {
        width: 85%;
        text-align: center;
        margin: 3rem auto 0; }
      .cont-subtitle {
        margin-bottom: 1.5rem;
        @include tablet {
          margin: 0 auto 1.5rem;
          justify-content: center; } } }
    .cont-map {
      width: 52%;
      position: relative;
      .TransformComponent-module_content__TZU5O {
        cursor: move; }
      .tools {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 1;
        top: 0;
        right: 0;
        button {
          width: 2.2rem;
          height: 2.2rem;
          background: var(--theme-btn-map);
          border-radius: 0;
          font-size: 1.4rem;
          padding: 0;
          border: none;
          overflow: hidden;
          cursor: pointer;
          transition: .4s all;
          opacity: 1;
          color: var(--theme-color-btn-map);
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-of-type {
            margin-bottom: .5rem; }
          &:hover, &:focus {
            outline: none;
            padding: 0;
            opacity: .8; } } }
      @include tablet {
        width: 100%; }
      .image-map {
        height: 20rem;

        img {
          width: 100%; } } } } }
