@import "../../../assets/sass/variables.sass";

.cont-about {
  height: 100%;
  display: flex;
  align-items: center;
  &>div {
    width: 100%;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse; }
    .cont-text {
      margin-bottom: 1rem;
      width: 100%;
      @include tablet {
        margin-top: 2rem;
        margin-bottom: 0; } }
    .cont-img {
      width: 100%;
      height: 15rem;
      overflow: hidden;
      @include tabletLandscape {
        height: 13rem; }
      @include smartphone {
        height: 10rem; }
      .img-about {
        width: 100%;
        height: 100%;
        object-fit: cover; } } } }
