@import "../../../assets/sass/variables.sass";

.wraper-not-found {
  display: flex;
  align-items: center;
  .cont-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      margin: 5rem 0 4rem; }
    &>div {
      text-align: center;
      h1 {
        font-size: 7.5rem;
        line-height: 100%;
        margin-bottom: .3rem; }
      h4 {
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.8rem; }
      .btn {
        display: block;
        width: fit-content;
        background: var(--theme-background-btn);
        border-radius: 0;
        margin: 2rem auto 0;
        font-family: $Anodina;
        font-weight: $bold;
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: .65rem 1.8rem .89rem;
        color: var(--theme-btn);
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: 0.4s all;
        @include smartphone {
          font-size: 1.2rem;
          line-height: 1.2rem; }
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          left: -50%;
          bottom: 0;
          transition: 0.4s all ease-in;
          transform: skew(50deg);
          transform-origin: top left;
          z-index: -1; }
        &:hover {
          color: var(--theme-background-btn);
          &::after {
            width: 160%;
            background: var(--theme-btn-hover); } } } } } }
