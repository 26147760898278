@import "./variables";

.light {
    --theme-color: #A80000;
    --theme-page-background: #{$white};
    --theme-page-text: #{$text-color};
    --theme-page-logo: #{$black};
    --theme-icon-btn: #{$gray-btn};
    --theme-background-btn: #{$black};
    --theme-background-hover-btn: #{$black};
    --theme-hover-icon-btn: #{$light-gray};
    --theme-color-link-disdable: #{$black};
    --theme-text-footer: #{$black};
    --theme-menu-icon: #{$dark};
    --theme-link-menu: #{$gray-link};
    --theme-hover-link-menu: #{$white};
    --theme-sub-link-menu: #{$sublink-light};
    --theme-short-title: #{$black};
    --theme-field: #{$black};
    --theme-field-hover: #{$color-field-hover};
    --theme-field-focus: #{$color-field-focus};
    --theme-btn: #{$light-gray};
    --theme-btn-hover: #{$color-btn};
    --theme-background-btn: #{$black};
    --theme-field-text: #{$dark};
    --background-gradient: #{$light-gradient};
    --theme-page-title: #{$dark};
    --theme-background-map: invert(1);
    --theme-background-arrow: #{$color-arrow-dark};
    --theme-background-arrow-hover: #{$color-arrow-dark-hover};
    --theme-background-btn-social: #{$black};
    --theme-logo: invert(1);
    --theme-btn-map: #{$black};
    --theme-color-btn-map: #{$white};
    --theme-logo-ikiga: initial; }
.dark {
    --theme-color: #0000A8;
    --theme-page-background: #{$black};
    --theme-page-text: #{$gray-btn};
    --theme-page-logo: #{$white};
    --theme-icon-btn: #{$black};
    --theme-background-btn: #{$gray-btn};
    --theme-background-hover-btn: #{$light-gray};
    --theme-hover-icon-btn: #{$black};
    --theme-color-link-disdable: #{$light};
    --theme-text-footer: #{$text-footer};
    --theme-menu-icon: #{$white};
    --theme-link-menu: #{$black};
    --theme-hover-link-menu: #{$dark};
    --theme-sub-link-menu: #{$sublink};
    --theme-short-title: #{$title-light};
    --theme-field: #{$color-field};
    --theme-btn: #{$black};
    --theme-btn-hover: #{$gray-social};
    --theme-background-btn: #{$light-gray};
    --theme-field-hover: #{$color-field-hover};
    --theme-field-focus: #{$color-field-focus};
    --theme-field-text: #{$white};
    --background-gradient: #{$dak-gradient};
    --theme-page-title: #{$white};
    --theme-background-map: initial;
    --theme-background-arrow: #{$color-arrow-light};
    --theme-background-arrow-hover: #{$color-arrow-light-hover};
    --theme-background-btn-social: #{$gray-social};
    --theme-logo: initial;
    --theme-btn-map: #{$light-gray};
    --theme-color-btn-map: #{$black};
    --theme-logo-ikiga: grayscale(1) invert(1); }
