@import "../../../assets/sass/variables.sass";

.cont-typologies {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &>div {
    width: 100%; }
  .slick-slider {
    @include smartphone {
      padding-bottom: 5.5rem; } }
  .slick-slide {
    padding: 0 1px; }
  .slick-arrow {
    font-size: 2.5rem;
    color: $light-gray;
    background: var(--theme-background-arrow);
    width: 1.6rem;
    height: 80%;
    border-radius: 4px;
    transition: .5s all;
    display: flex !important;
    justify-content: center;
    align-items: center;
    @include tablet {
      height: 50%;
      width: 1.2rem;
      font-size: 1.8rem; }
    @include smartphoneLandscape {
      transform: translate(0, 0);
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 0;
      bottom: 0;
      top: auto; }
    &:before {
      display: none; }
    &.slick-next {
      right: 0; }
    &.slick-prev {
      left: 0;
      @include smartphone {
        left: auto;
        right: 3.5rem; } }
    &.slick-disabled {
      opacity: 0;
      @include smartphone {
        opacity: .3;
        pointer-events: none; } }
    &:hover, &:focus {
      background: var(--theme-background-arrow-hover);
      color: $light-gray; } }
  .slick-list {
    width: calc( 100% - 8rem );
    margin: 0 auto;
    @include tablet {
      width: calc( 100% - 6.5rem ); }
    @include smartphoneLandscape {
      width: 100%; }
    div:focus {
      outline: none; } }
  .item {
    .cont-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .cont-img-slide {
        width: 58%;
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet {
          width: 100%; }
        img {
          width: 100%; } }
      .caption {
        width: 37%;
        text-align: right;
        @include tablet {
          width: 100%; }
        h6 {
          font-size: 1.05rem;
          font-family: $Anodina;
          font-weight: $bold;
          background: var(--theme-page-title);
          width: fit-content;
          margin-left: auto;
          color: var(--theme-page-background);
          padding: .4rem .6rem .55rem;
          line-height: 100%;
          text-transform: uppercase;
          @include tabletLandscape {
            font-size: .95rem; } }
        .title-caption {
          font-family: $Blatant;
          font-weight: $bold;
          font-size: 4rem;
          margin-bottom: 2.5rem;
          color: var(--theme-page-text);
          line-height: 3rem;
          sup {
            font-size: 2rem; }
          @include tabletLandscape {
            font-size: 3.2rem;
            sup {
              font-size: 1.5rem; } } }
        ul {
          padding: 0;
          list-style: none;
          li {
            position: relative;
            span {
              font-family: $Anodina;
              font-weight: $regular;
              font-size: 1.05rem;
              position: relative;
              padding-left: 1rem;
              line-height: 100%;
              color: var(--theme-page-text);
              text-transform: uppercase;
              @include smartphone {
                font-size: .88rem; }
              &::before {
                position: absolute;
                font-family: "FontAwesome";
                content: "\f111";
                font-size: 6px;
                left: 0;
                top: .2rem; } } } } } } } }
