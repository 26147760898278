@import "../../../assets/sass/variables.sass";
.cover {
  &__cont {
    .clip-animation {
      width: 26vw;
      height: 26vw;
      clip-path: polygon(35% 0%,65% 0%,65% 35%,102% 35%,102% 60%,65% 60%,65% 102%,35% 102%,35% 60%,0% 60%,0% 35%,35% 35%);
      position: relative;
      overflow: hidden;
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        position: absolute;
        width: 99.84%;
        height: 99.84%;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        animation-name: turning;
        animation-duration: 3s;
        animation-timing-function: ease-out;
        animation-delay: 4s;
        animation-direction: alternate;
        animation-fill-mode: none;
        animation-play-state: running; }

      @include tabletLandscape {
        width: 22rem;
        height: 22rem;
        margin-bottom: 4rem; }
      @include smartphone {
        width: 16rem;
        height: 16rem;
        margin-bottom: 3rem; } } }
  .wraper {
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%; }
    &.home {
      @include tablet {
        flex-direction: column-reverse;
        margin: 1.5rem 0 3rem; } } } }

@keyframes turning {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.3); } }

