@import "../../../assets/sass/variables.sass";

.cont-concept-project {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  .con-text {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 7.4rem;
    padding-right: 1.5rem;
    @include tabletLandscape {
      width: 50%; }
    @include tablet {
      width: 100%;
      text-align: center;
      padding-bottom: 3rem; }
    .iconPlus {
      width: 2.3rem;
      height: 2.3rem;
      position: relative;
      margin-bottom: 1.4rem;
      @include tablet {
        margin-left: auto;
        margin-right: auto; }
      &:after, &:before {
        position: absolute;
        content: "";
        background: var(--theme-page-title); }
      &:before {
        width: .6rem;
        height: 2.3rem;
        top: 0;
        left: calc(50% - .3rem); }
      &:after {
        width: 2.3rem;
        height: .6rem;
        top: calc(50% - .3rem);
        left: 0; } } }
  .cont-image {
    width: 67%;
    overflow: hidden;
    &>div {
      height: 100%;
      width: 100%;
      &>div {
        height: 100%;
        width: 100%; } }
    @include tabletLandscape {
      width: 50%; }
    @include tablet {
      width: 100%;
      padding-left: 0; }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top left; } } }
