@import "../../../assets/sass/variables.sass";
.modal {
  width: 100%;
  height: 100%;
  &>div {
    &>div {
      &>div {
        &:first-child {
          position: relative;
          border-radius: 0 !important;
          background-color: $light-gray !important;
          width: 55%;
          box-sizing: border-box;
          overflow: hidden;
          padding: 4rem 2%;
          max-height: 70vh;
          @include tabletLandscape {
            overflow-y: scroll;
            width: 55%; }
          @include tablet {
            width: 70%;
            padding: 3.5rem 4%;
            padding-bottom: 2rem;
            max-height: 85vh; }
          @include smartphoneLandscape {
            width: 85%; }
          p {
            font-size: .95rem;
            line-height: 1.05rem; } }
        &:last-child {
          background-color: rgba( $dark, .80) !important;
          height: 100vh !important; } } } }
  .contModal {
    text-align: start;
    position: relative;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    @include smartphoneLandscape {
      display: flex;
      flex-direction: column; }
    .itemLeft {
      max-width: 45%;
      text-align: justify;
      padding-right: 6%;
      @include smartphoneLandscape {
        max-width: 100%;
        padding-right: 0;
        padding-bottom: 20px; }
      .subtitle {
        color: $black;
        padding-bottom: 1.5rem; } }
    .itemRight {
      width: 100%;
      height: 400px;
      .cont-img-slide {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          transition: .4s ease all; } } } }
  .btn-back {
    position: fixed;
    top: 1.2rem;
    left: 1.2rem;
    font-size: 1.8rem;
    color: $black;
    transition: .5s all;
    display: flex;
    align-items: center;
    @include tablet {
      top: 1rem;
      left: 1rem; }
    @include smartphoneLandscape {
      top: .6rem;
      left: .6rem; }
    &:hover {
      color: $dark;
      animation-name: hvr-wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      cursor: pointer; } } }
