@import "../../../assets/sass/variables.sass";

.cont-about {
  display: flex;
  align-items: center; }
.cont-gallery {
  display: flex;
  align-items: center;
  &>div {
    width: 100%;
    height: 94%; }
  .slick-slider {
    height: 100%;
    @include smartphone {
      padding-bottom: 3rem; } }
  .slick-slide {
    padding: 0 1.2rem;
    height: 100%;
    &>div {
      height: 100%; }
    @include tablet {
      padding: 0.3rem; } }
  .slick-arrow {
    font-size: 2.5rem;
    color: $light-gray;
    background: var(--theme-background-arrow);
    width: 1.6rem;
    height: 100%;
    border-radius: 4px;
    transition: .5s all;
    display: flex !important;
    justify-content: center;
    align-items: center;
    @include tablet {
      height: 50%;
      width: 1.2rem;
      font-size: 1.8rem; }
    @include smartphoneLandscape {
      transform: translate(0, 0);
      height: 2.8rem;
      width: 2.8rem;
      border-radius: 0;
      bottom: 0;
      top: auto; }
    &:before {
      display: none; }
    &.slick-next {
      right: 0; }
    &.slick-prev {
      left: 0;
      @include smartphone {
        left: auto;
        right: 3.5rem; } }
    &.slick-disabled {
      opacity: 0;
      @include smartphone {
        opacity: .3;
        pointer-events: none; } }
    &:hover, &:focus {
      background: var(--theme-background-arrow-hover);
      color: $light-gray; } }
  .slick-list {
    height: 100%;
    width: calc( 100% - 6rem );
    margin: 0 auto;
    @include tablet {
      width: 80vw; }
    @include smartphoneLandscape {
      width: 100%; }
    div:focus {
      outline: none; }
    .slick-track {
      height: 100%; } }
  .item {
    height: 100%;
    cursor: default !important;
    .cont-img-slide {
      height: calc( 100% - 90px - 1.5rem);
      overflow: hidden;
      margin-bottom: 1.5rem;
      @include tablet {
        height: 400px; }
      @include smartphoneLandscape {
        height: 200px; }
      img {
        height: 100%;
        object-fit: cover;
        object-position: top;
        filter: grayscale(1);
        transition: .4s ease all; } }
    .caption {
      .title-caption {
        font-family: $Blatant;
        font-weight: $bold;
        font-size: 1.8rem;
        margin-bottom: .8rem;
        @include smartphone {
          font-size: 1.6rem; } } }
    &:hover {
      img {
        filter: grayscale(0); } } }
  .slick-current {
    &.slick-active {
      img {
        filter: grayscale(0) !important; } } } }
.loader-image {
  width: 100%;
  height: 100%;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  transition: .4s ease all; }
