@import "../../../assets/sass/variables.sass";

.cont-about {
  display: flex;
  align-items: center;
  &>div {
    width: 100%;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse; }
    .cont-text {
      margin-bottom: 20rem;
      width: 50%; } } }
