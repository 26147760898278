@mixin tv {
  @media screen and (min-width: 1370px) {
    @content; } }

@mixin tabletLandscape {
  @media screen and (max-width: 1024px) {
    @content; } }

@mixin tablet {
  @media screen and (max-width: 900px) {
    @content; } }

@mixin smartphoneLandscape {
  @media screen and (max-width: 600px) {
    @content; } }

@mixin smartphone {
  @media screen and (max-width: 480px) {
    @content; } }
