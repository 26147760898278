@import "../../../assets/sass/variables.sass";

.box-icon {
  svg {
    width: 2.5rem;
    .cls-trofeo {
      fill: $dark; } } }

.textPopover {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      padding-bottom: .34rem;
      line-height: 105%;
      &:last-of-type {
        padding-bottom: 0; } } } }

.top {
  &::after {
    bottom: -8px; } }

.bottom {
  &::after {
    top: -8px; } }
