@import "../../../assets/sass/variables.sass";
.preloader-chasing-squares {
  border: 0;
  padding: 0;
  background: transparent;
  outline: none;
  width: 39px;
  height: 39px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 5px;
  .square {
    display: inline-block;
    width: 11px;
    height: 11px;
    opacity: 1;
    background: var(--theme-menu-icon);
    transition: .4s all;
    animation: focusfade 2.5s infinite; }
  .bar {
    display: block;
    width: 30px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: var(--theme-menu-icon);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 1.5s cubic-bezier(0.77,0.2,0.05,1.0), background 1.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 1.6s ease;
    &:first-child {
      transform-origin: 0% 0%; }
    &:nth-last-child(2) {
      transform-origin: 0% 100%; } }
  &.active-btn {
    .square {
      background: var(--theme-hover-icon-btn); }
    .bar {
      opacity: 1;
      transform: rotate(45deg) translate(4px, -1.5px);
      background: var(--theme-hover-icon-btn);
      &:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2); }
      &:nth-last-child(1) {
        transform: rotate(-45deg) translate(0, -1px); } } } }
.preloader-chasing-squares .square:nth-child(2) {
  animation-delay: .7s; }
.preloader-chasing-squares .square:nth-child(3) {
  animation-delay: 2.1s; }
.preloader-chasing-squares .square:nth-child(4) {
  animation-delay: 1.4s; }
.menu {
  position: relative;
  width: 2.438rem;
  height: 2.438rem;
  .btn-menu {
    position: absolute;
    z-index: 2;
    right: -5px;
    top: -5px; }
  .nav {
    position: absolute;
    background: var(--theme-menu-icon);
    top: -1.6rem;
    right: -1.6rem;
    min-width: 0;
    overflow: hidden;
    z-index: 1;
    transition: 2s all;
    min-height: 0;
    height: 0;
    width: 0;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);
    @include smartphone {
      top: -.8rem;
      right: -.8rem; }
    &>ul {
      padding: 5.5rem 1.6rem 2rem;
      opacity: 0;
      transition: 2s opacity; }
    &.active {
      width: 17rem;
      height: 20rem;
      @include smartphone {
        width: 93vw; }
      &>ul {
        opacity: 1; } }
    ul {
      &.content-sublinks {
        margin-top: 1rem;
        padding: 0;
        padding-bottom: .2rem; }
      li {
        list-style: none;
        text-align: right;
        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0; }
        a {
          font-family: $Anodina;
          font-size: 1.2rem;
          line-height: 1.2rem;
          position: relative;
          padding-bottom: .4rem;
          transition: all .3s;
          display: block;
          width: fit-content;
          margin-left: auto;
          &::after {
            content: '';
            width: 100%;
            height: .1rem;
            background: var(--theme-hover-link-menu);
            position: absolute;
            bottom: 0;
            right: 0;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 2s; }
          &:hover {
            &::after {
              transform: scaleX(1); } } }
        &.link {
          margin-bottom: 1rem;
          a {
            font-weight: 700;
            color: var(--theme-link-menu);
            &:hover {
              color:  var(--theme-hover-link-menu); }
            &.active-link {
              color: var(--theme-hover-link-menu);
              &::after {
                transform: scaleX(1); } } }
          &:last-child {
            margin-bottom: 0; } }
        &.sub-link {
          margin-bottom: .3rem;
          a {
            padding-bottom: .3rem;
            font-weight: 400;
            color: var(--theme-sub-link-menu);
            &:hover {
              color:  var(--theme-hover-link-menu); }
            &.active-sublink {
              color: var(--theme-hover-link-menu);
              &::after {
                transform: scaleX(1); } } } } } } } }

@keyframes focusfade {
  0% {
    opacity: 1; }

  30% {
    opacity: 1; }

  60% {
    opacity: .1; }

  75% {
    opacity: 0; }

  100% {
    opacity: 1; } }


