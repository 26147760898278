@import '~font-awesome/css/font-awesome.min.css';
@import "./fonts";
@import "./mixins";
@import "./variables";
@import "./theme";

* {
  margin: 0;
  box-sizing: border-box;
  text-decoration: none; }

.fullscreen {
  z-index: 11000;
  position: absolute; }

.react-images__blanket {
  @include smartphone {
    background-color: rgba(0, 0, 0, 0.95) !important; } }

body {
  font-family: $Anodina;
  font-weight: 400; }
p {
  font-family: $Anodina;
  font-size: 1.05rem;
  font-weight: 400;
  color: var(--theme-page-text);
  margin-bottom: .8rem;
  line-height: 1.3rem;
  @include smartphone {
    font-size: .95rem;
    line-height: 1.2rem; }
  &:last-of-type {
    margin-bottom: 0; } }
input, textarea, button {
  outline: none;
  background: transparent;
  border: none;
  &:hover, &:focus {
    outline: none; } }
.cover {
  height: 100vh;
  width: 100%;
  color: var(--theme-page-text);
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  @include tablet {
    height: auto; }
  &__cont {
    height: 100%;
    width: 100%;
    background: var(--theme-page-background);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
    .wraper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 3.5rem;
      height: calc( 100% - (12rem + 7.4rem) );
      min-height: calc( 100% - (12rem + 7.4rem) );
      @include tabletLandscape {
        padding: 0rem 2.5rem; }
      @include tablet {
        height: 100%;
        margin-bottom: 3rem; }
      @include smartphoneLandscape {
        padding: 0 1.5rem; }
      .item {
        height: 100%;
        &:first-child, &:last-child {
          width: calc(100% / 2 - 15rem);
          @include tabletLandscape {
            width: calc(100% / 2 - 11rem); }
          @include tablet {
            width: 100%; } }
        &:nth-child(2) {
          width: 30rem;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          @include tabletLandscape {
            width: 22rem; }
          @include tablet {
            width: 100%; } } }
      &.wraper-two {
        .left {
          width: 25%;
          @include tablet {
            width: 100%; } }
        .right {
          width: 70%;
          padding-right: 4rem;
          height: 100%;
          @include tabletLandscape {
            padding-right: 1.4rem;
            padding-left: 1.4rem; }
          @include tablet {
            width: 100%;
            padding-right: 0rem;
            padding-left: 0rem;
            margin-top: 4rem; }
          &__cont {
            width: 100%;
            height: 100%;
            &>div {
              height: 100%; } } }
        .right2 {
          width: 70%;
          padding-right: 4rem;
          height: 100%;
          @include tabletLandscape {
            padding-right: 1.4rem;
            padding-left: 1.4rem; }
          @include tablet {
            width: 100%;
            padding-right: 0rem;
            padding-left: 0rem;
            margin-top: 2rem; }
          &__cont {
            width: 100%;
            height: 100%;
            &>div {
              height: 100%; } } } }
      &.wraper-three {
        margin-top: 1rem;
        .left {
          width: 45%;
          @include tablet {
            width: 100%; } }
        .right {
          width: 55%;
          height: 100%;
          @include tablet {
            width: 100%; }
          &__cont {
            width: 75%;
            margin: 0 auto;
            height: 100%;
            @include tablet {
              width: 100%; } } } }
      &.wraper-cover {
        @include tablet {
          flex-direction: column-reverse; } } } }
  &.cover-two {
    position: relative;
    .wraper {
      &.wraper-two {
        .left {
          width: 26%;
          padding-right: 1.5rem;
          @include tablet {
            width: 100%;
            padding-right: 0; } }
        .right {
          width: 74%;
          padding-right: 2rem;
          padding-left: 0;
          @include tablet {
            padding-right: 0;
            width: 100%; } } } }

    .cover__cont {
      background: var(--background-gradient); }
    .img-background {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      filter: grayscale(1);
      &.blur {
        filter: blur(5px) grayscale(1); } }
    .lumina-cover {
      background-image: url("./../images/cover.jpg"); }
    .torre-texao-cover {
      background-image: url("./../images/cover-torre-texao.jpg"); }
    .puerta-del-sol-cover {
      background-image: url("./../images/cover.jpg"); }
    .via-maria-cover {
      background-image: url("./../images/cover-via-maria.jpg"); }
    .ikiga-cover {
      background-image: url("./../images/cover-ikiga.jpg"); } }

  &.cover-three {
    .cover__cont {
      .wraper {
        padding-right: 0;
        height: calc( 100% - 7.4rem);
        @include tablet {
          margin-bottom: 0; }
        .left {
          padding-bottom: 7.4rem;
          @include tablet {
            padding-right: 1.5rem;
            padding-bottom: 0; } }
        .right {
          padding: 0; } } }
    .footer {
      margin-top: -7.4rem;
      @include tablet {
        margin-top: 0; } } } }
.title {
  color: var(--theme-page-title);
  font-size: 2.5rem;
  font-family: $Blatant;
  font-weight: $bold;
  margin-bottom: 0rem;
  @include smartphone {
    font-size: 2rem;
    margin-bottom: 2rem; } }
.cont-title-project {
  width: 100%;
  padding: 0 2.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  @include tablet {
    justify-content: center;
    padding: 0;
    margin-bottom: 3rem; } }
.cont-subtitle {
  display: flex;
  align-items: flex-start;
  .marker {
    font-size: 2.1rem;
    color: var(--theme-page-title);
    margin-right: .8rem; }
  .subtitle {
    font-size: 2.2rem;
    font-family: $Blatant;
    font-weight: $bold;
    color: var(--theme-page-title);
    line-height: 100%;
    text-transform: uppercase; } }
.left__menu {
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 2rem;
      &:last-of-type {
        margin-bottom: 0; }
      @include tablet {
        text-align: center; }
      @include smartphone {
        margin-bottom: 1rem; }
      &:first-child {
        a {
          span {
            opacity: .5; } } }
      &:nth-child(2) {
        a {
          span {
            opacity: .4; } } }
      &:nth-child(3) {
        a {
          span {
            opacity: .3; } } }
      &:last-child {
        margin-bottom: 0;
        a {
          span {
            opacity: .2; } } }
      a {
        display: block;
        color: var(--theme-color-link-disdable);
        line-height: 1;
        span {
          transition: .4s all ease; }
        .name-project {
          font-family: $Blatant;
          font-weight: $bold;
          display: block;
          font-size: 1.8rem;
          text-transform: capitalize; }
        .subtitle {
          font-family: $Blatant;
          font-weight: $regular;
          font-size: 1.1rem;
          margin-top: .25rem;
          display: block; }
        &:hover {
          span {
            opacity: 1; } }
        &.is-active {
          span {
            opacity: 1; } } } } }
  &.lateral-menu {
    ul {
      li {
        margin-bottom: 1.5rem;
        &:last-of-type {
          margin-bottom: 0; }
        a {
          span {
            text-transform: uppercase; } } } } } }
.dropdawn {
  margin-top: 1rem;
  width: 100%;
  background: transparent;
  color: var(--theme-color-link-disdable);
  border: none;
  text-align: left;
  font-family: $Anodina;
  font-weight: $regular;
  font-size: 1.2rem;
  text-transform: capitalize;
  position: relative;
  padding: .6rem 1.8rem .6rem .5rem;
  border-bottom: .1rem solid $gray-btn;
  animation: .3s all;
  @include smartphone {
    font-size: 1rem;
    padding-left: 1px;
    padding-right: 1.5rem; }
  &:hover, &:focus {
    outline: none; }
  &::after {
    position: absolute;
    font-family: "FontAwesome";
    content: "\f107";
    right: .5rem;
    animation: .3s all;
    font-size: 1.5rem;
    @include smartphone {
      right: 1px; } }
  &.open {
    color: $white;
    background: rgba($dark , .8);
    &::after {
      transform: rotate(180deg); } } }
.nav-dropdawn {
  top: 60px;
  background: rgba($dark , .8);
  padding: .5rem .5rem 1rem;
  @include smartphone {
    top: 56px; }
  .left__menu {
    ul {
      li {
        margin: 1rem 0;
        a {
          text-align: left;
          color: $white;
          span {
            opacity: 1;
            &.name-project {
              font-size: 1.1rem;
              font-weight: $regular;
              font-family: $Anodina; }
            &.is-active {
              color: rgba($white, .6); } } } } } } }
.lumina {
  .title-project {
    img {
      width: 11rem;
      filter: var(--theme-logo); } } }
.torre-texao {
  .title-project {
    img {
      width: 20rem;
      filter: var(--theme-logo);
      @include smartphone {
        width: 15rem; } } } }

.ikiga {
  .title-project {
    img {
      width: 20rem;
      filter: var(--theme-logo);
      @include smartphone {
        width: 15rem; } } } }

.via-maria {
  .title-project {
    img {
      width: 18rem;
      filter: var(--theme-logo);
      @include smartphone {
        width: 15rem; } } } }

.puerta-del-sol {
  .title-project {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    color: var(--theme-page-title);
    line-height: 1.2;
    span {
      display: block; }
    img {
      width: 18rem;
      filter: var(--theme-logo);
      @include smartphone {
        width: 15rem; } } } }
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  transition: .4s ease all;
  color: var(--theme-page-title); }
