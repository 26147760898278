@import "../../../assets/sass/variables.sass";

.imgIkiga {
  filter: var(--theme-logo-ikiga);
  img {
    width: 30vw !important;
    @include tabletLandscape {
      width: 60vw !important; }
    @include tablet {
      width: 80vw !important;
      padding-left: 0; } } }

.brochure {
  margin-right: 200px; }
