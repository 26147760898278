@import "../../../assets/sass/variables.sass";

.cont-form {
  height: 100%;
  display: flex;
  align-items: center;
  @include tablet {
    margin-top: 4rem; }
  .form {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--theme-page-background) inset !important;
      -webkit-text-fill-color: var(--theme-field) !important; }
    .MuiFormControl-root {
      margin-bottom: .6rem;
      padding-bottom: 1.1rem; }
    .MuiFormLabel-root {
      color: var(--theme-field);
      font-family: $Anodina;
      font-weight: $regular;
      font-size: 1.5rem;
      line-height: 100%;
      &.Mui-focused {
        color: var(--theme-field-focus); } }
    .MuiInput-underline {
      &::after {
        border-color: var(--theme-field-focus); }
      &::before {
        border-color: var(--theme-field);
        border-width: .1rem; }
      &:not(.Mui-disabled):before {
        transition: .4s ease all; }
      &:hover {
        &:not(.Mui-disabled):before {
          border-color: var(--theme-field-hover); } } }
    .MuiInputBase-input {
      color: var(--theme-field-text); }
    .MuiFormHelperText-root.Mui-error {
      font-family: $Anodina;
      position: absolute;
      bottom: 0;
      margin: 0;
      font-weight: $regular;
      font-size: 10px; }
    label + .MuiInput-formControl {
      margin-top: 22px; }
    .cont-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .btn-save {
        background: var(--theme-background-btn);
        border-radius: 0;
        margin-left: auto;
        font-family: $Anodina;
        font-weight: $bold;
        font-size: 1.3rem;
        line-height: 100%;
        padding: .8rem 1.5rem 1rem;
        color: var(--theme-btn);
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: 0.4s all;
        border-radius: 2px;
        svg {
          margin-left: .5rem; }
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          left: -50%;
          bottom: 0;
          transition: 0.4s all ease-in;
          transform: skew(50deg);
          transform-origin: top left;
          z-index: -1; }
        &:hover {
          color: var(--theme-background-btn);
          &::after {
            width: 160%;
            background: var(--theme-btn-hover); } } } } } }
