@import "../../../assets/sass/variables.sass";
.modal-iso {
  &>div {
    &>div {
      &>div {
        &:first-child {
          position: relative;
          border-radius: 0 !important;
          background-color: $light-gray !important;
          width: 45%;
          box-sizing: border-box;
          overflow: hidden;
          padding: 4rem 6%;
          height: auto;
          max-height: 70vh;
          @include tabletLandscape {
            width: 55%; }
          @include tablet {
            width: 70%;
            padding: 3.5rem 4%;
            max-height: 85vh; }
          @include smartphoneLandscape {
            width: 85%; }
          p {
            font-size: .95rem;
            line-height: 1.05rem; } }
        &:last-child {
          background-color: rgba( $dark, .80) !important;
          height: 100vh !important; } } } }
  .contModal {
    text-align: center;
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include smartphoneLandscape {
      align-items: flex-start; } }
  .btn-left {
    position: fixed;
    top: 6.5rem;
    left: 1.2rem;
    font-size: 2rem;
    color: $dark;
    @include smartphoneLandscape {
      font-size: 1.5rem;
      left: 0.2rem;
      z-index: 1000; }
    &:hover {
      color: $dark;
      animation-name: hvr-wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      cursor: pointer; } }
  .btn-right {
    position: fixed;
    top: 6.5rem;
    right: 1.2rem;
    font-size: 2rem;
    color: $dark;
    @include smartphoneLandscape {
      font-size: 1.5rem;
      right: 0.2rem;
      z-index: 1000; }
    &:hover {
      color: $dark;
      animation-name: hvr-wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      cursor: pointer; } }
  .btn-back {
    position: fixed;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: $black;
    transition: .5s all;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include tablet {
      top: 1rem;
      left: 1rem; }
    @include smartphoneLandscape {
      top: .6rem;
      left: .6rem; } }
  .logo-iso {
    width: 14rem;
    margin-bottom: 1.5rem;
    @include tablet {
      width: 12rem;
      margin-bottom: 1.4rem; }
    @include smartphoneLandscape {
      width: 10rem; }
    .cls-1 {
      fill: $black; } } }

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
