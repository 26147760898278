@import "../../../assets/sass/variables.sass";

.sticky-share-container {
  position: fixed;
  right: 0;
  top: calc( 50% - 5.5rem );
  @include tablet {
    top: calc( 50% + 5.5rem); }
  .cont {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a {
          background: var(--theme-background-btn-social);
          width: 3rem;
          height: 3rem;
          @include tablet {
            width: 2rem;
            height: 2rem; }
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &::before {
            background: $sublink;
            content: "";
            left: 0;
            height: 100%;
            position: absolute;
            top: 0;
            transition: 0.25s cubic-bezier(0.165, 0.64, 0.84, 1);
            width: 0;
            z-index: 0; }
          &::after {
            background: $sublink;
            content: "";
            left: 0;
            height: 100%;
            position: absolute;
            top: 0;
            transition: 0.25s cubic-bezier(0.165, 0.64, 0.84, 1);
            width: 0;
            z-index: 0; }
          svg {
            color: $text-social;
            font-size: 1.4rem;
            z-index: 1;
            transition: 0.25s all; }
          &:hover {
              &::before {
                width: 100%; }
              svg {
                color: $white; } } } } } } }
