@import "../../../assets/sass/variables.sass";

.cont-timeline {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &>div {
    width: 100%;
    position: relative;
    &>div>div {
      width: 100%;
      position: relative;
      @include tablet {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline; } } }
  .line {
    width: 100%;
    background: var(--theme-short-title);
    height: .1rem;
    @include tablet {
      width: .1rem;
      height: 100%;
      position: absolute;
      right: calc(50% - .05rem);
      z-index: 0; } }

  ul {
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    @include tablet {
        margin: 0;
        width: 50%; }
    li {
      list-style: none;
      width: calc(100% / 7);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include tablet {
        width: 100%;
        padding: 1.2rem 0; }
      button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        background: transparent;
        border: none;
        color: var(--theme-page-text);
        @include tablet {
          flex-direction: row; }
        &:after, &:before {
          content: "";
          position: absolute;
          background: var(--theme-menu-icon);
          @include tablet {
            z-index: 1; } } } }
    &.top {
      margin-bottom: -.05rem;
      li {
        @include tablet {
          justify-content: flex-end; }
        button {
          padding-bottom: 1.2rem;
          padding-top: 1.5rem;
          @include tablet {
            padding: .8rem 2rem .8rem 1.5rem; }
          &:after {
            width: 6px;
            height: 9px;
            bottom: 0;
            @include tablet {
              right: 0;
              bottom: auto;
              width: 9px;
              height: 6px; } }
          &:before {
            width: 18px;
            height: .2rem;
            bottom: 0;
            @include tablet {
              bottom: auto;
              right: 0;
              width: .2rem;
              height: 18px; } } } } }
    &.bottom {
      margin-top: -.05rem;
      li {
        @include tablet {
          justify-content: flex-start; }
        button {
          padding-top: 1.2rem;
          padding-bottom: 1.5rem;
          @include tablet {
            padding: .8rem 1.5rem .8rem 2rem; }
          &:after {
            width: 18px;
            height: .2rem;
            top: 0;
            @include tablet {
              left: 0;
              top: auto;
              height: 18px;
              width: .2rem; } }
          &:before {
            width: 6px;
            height: 9px;
            top: 0;
            @include tablet {
              left: 0;
              top: auto;
              height: 6px;
              width: 9px; } } } } } } }
